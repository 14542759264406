import { ButtonGroup, CardHeader, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, Paper, Radio, RadioGroup, TextField } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useState } from 'react';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import ItemSelectionAsync from '../components/ItemSelectionAsync';
import SingleDate from '../components/SingleDate';
import { ORG_TYPE, USERTYPES } from '../Constants';
import { LeadService } from '../Services/LeadService';
import { MasterService } from '../Services/MasterService';
import { formatNumber, format_without_time } from '../Utils';

// const Mailto = ({ email, subject, body, children }) => {
// 	return (
// 	  <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${body ? encodeURIComponent(body) : ''}`}>{children}</a>
// 	);
// };

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	subtitle: {
		// marginLeft: theme.spacing(2),
		flex: 1,
		fontWeight: 600
		// color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		marginLeft: 16,
		marginRight: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	required: {
		color: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
		// bottom: 0,
		bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	},
	truncate: {
		maxWidth: 200,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'left'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateLeadDialog(props) {
	const { showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D } = useContext(AppContext);
	const { id } = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [terminalOptions, setTerminalOptions] = React.useState([]);
	const [witnessNoOfYearsOptions, setWitnessNoOfYearsOptions] = React.useState([]);
	const [incomeRange, setIncomeRange] = React.useState([]);
	const [bmOptions, setBMOptions] = React.useState([]);
	const [smOptions, setSMOptions] = React.useState([]);
	const [shOptions, setSHOptions] = React.useState([]);
	const [masterCharges, setCharges] = React.useState([]);
	// const charges_software = 175;
	const [lead, setLead] = React.useState({ other_ap: "n", "witness_know_the_ap": "n", sameAsOffice: true, charges_software: false, tnc: false });
	const [employees, setEmployees] = useState([])
	// const hiddenFileInput = React.useRef(null);

	const [photosFileOb, setPhotosFileOb] = React.useState(null);
	const [panFileOb, setPANFileOb] = React.useState(null);
	const [qualificationFileOb, setQualificationFileOb] = React.useState(null);
	const [officeAddFileOb, setOfficeAddFileOb] = React.useState(null);
	const [resAddFileOb, setResAddFileOb] = React.useState(null);
	const [chequeFileOb, setChequeFileOb] = React.useState(null);
	const [nismFileOb, setNISMFileOb] = React.useState(null);
	const [offSpaceFileOb, setOffSpaceFileOb] = React.useState(null);
	const [perVisitFileOb, setPerVisitFileOb] = React.useState(null);

	const [qualificationOptions, setQualificationOptions] = React.useState([]);
	// const [loading, setLoading] = React.useState(false);
	const [errorList, setErrorList] = React.useState({ employees: [] });
	const fieldMargin = "dense";
	const fieldSize = "small";
	const maxEmployees = 5;

	React.useEffect(() => {
		setOpen(props.open);
		if (props.open) {
			setLead({ other_ap: "n", "witness_know_the_ap": "n", sameAsOffice: true, charges_software: false, tnc: false });
			fetchData();
		}
		return () => {
			setLead({ other_ap: "n", "witness_know_the_ap": "n", sameAsOffice: true, charges_software: false, tnc: false });
			setOpen(false);
		}
	}, [props.open]);

	const fetchData = () => {

		let terminals = [];
		terminals.push({ name: 'Acumen (ODIN)', value: 'Acumen (ODIN)' });
		terminals.push({ name: 'Touch (Rupeeseed)', value: 'Touch (Rupeeseed)' });
		terminals.push({ name: 'No terminal', value: 'No terminal' });
		setTerminalOptions(terminals);

		let options = [];
		options.push({ name: 'Recently met', value: 'Recently met' });
		options.push({ name: '1-3 months', value: '1-3 months' });
		options.push({ name: '3-6 months', value: '3-6 months' });
		options.push({ name: '6-12 months', value: '6-12 months' });
		options.push({ name: 'Above 1 year', value: 'Above 1 year' });
		setWitnessNoOfYearsOptions(options);

		let qOptions = [];
		qOptions.push({ name: '10th', value: '10th' });
		qOptions.push({ name: '12th', value: '12th' });
		qOptions.push({ name: 'Under Grad', value: 'Under Grad' });
		qOptions.push({ name: 'Post Grad', value: 'Post Grad' });
		setQualificationOptions(qOptions);

		let ranges = [];
		ranges.push({ name: 'Below 1 Lakh', value: 'Below 1 Lakh' });
		ranges.push({ name: '1 Lakh to 5 Lakhs', value: '1 Lakh to 5 Lakhs' });
		ranges.push({ name: '5 Lakhs to 10 Lakhs', value: '5 Lakhs to 10 Lakhs' });
		ranges.push({ name: 'Above 10 Lakhs', value: 'Above 10 Lakhs' });
		setIncomeRange(ranges);

		showProgressDialog();
		Promise.all([MasterService.getAceUsers(USERTYPES.BRANCH), MasterService.getAceUsers(USERTYPES.STATEMG), MasterService.getAceUsers(USERTYPES.SALEHD), MasterService.getMasterCharges()])
			.then(datas => {
				if (datas[0]) {
					setBMOptions(datas[0]);
				}
				if (datas[1]) {
					setSMOptions(datas[1]);
				}
				if (datas[2]) {
					setSHOptions(datas[2]);
				}
				if (datas[3]) {
					setCharges(datas[3]);
				}
				closeProgressDialog();
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				// setLoading(false);
				closeProgressDialog();
			});

		// if(id){
		// 	// setLoading(true);
		// 	showProgressDialog();
		// 	PropertyService.getPropertyById(id)
		// 	.then(data => {
		// 		console.log(data);
		// 		if(data){
		// 			let unit = null;
		// 			// if(data.area_unit){
		// 			// 	unit = units.find(u => u.value === data.area_unit);
		// 			// }
		// 			let typeOb = null;
		// 			if(data.type){
		// 				typeOb = terminals.find(u => u.value === data.type);
		// 			}
		// 			setLead(property => ({...property, 
		// 								id: data.id, 
		// 								unit: unit,
		// 								name: data.name,
		// 								nickname: data.nickname,
		// 								address1: data.address1,
		// 								address2: data.address2,
		// 								city: data.city,
		// 								state: data.state,
		// 								pincode: data.pincode,
		// 								typeOb,
		// 								identifier: data.identifier,
		// 								geo_coordinates: data.geo_coordinates,
		// 								building_no: data.building_no,
		// 								area: data.area,
		// 								land_tax_date: data.land_tax_date,
		// 								tax_receipt_date: data.tax_receipt_date ? moment(data.tax_receipt_date).format(format_display) : null,
		// 								measurement: data.measurement,
		// 								plinth_area: data.plinth_area,
		// 								carpet_area: data.carpet_area,
		// 								comments: data.comments,
		// 								PHOTO: data.PHOTO || [],
		// 								LANDTAX: data.LANDTAX || [],
		// 								TAXRECEIPT: data.TAXRECEIPT || [],
		// 								DRAWINGPLAN: data.DRAWINGPLAN || [],
		// 								SKETCH: data.SKETCH || [],
		// 								alert_config: data.alert_config,
		// 							})			
		// 			);
		// 		}
		// 		closeProgressDialog();
		// 		// setLoading(false);
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 		showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
		// 			handleClose(false);
		// 			closeConfirmDialog1D();
		// 		}, 'OK');
		// 		// setLoading(false);
		// 		closeProgressDialog();
		// 	});
		// }
	}

	const handleClose = (showConfirm = false, shouldRefresh = false) => {
		if (showConfirm) {
			showConfirmDialog('Are you sure you want to close? Any changes made will be lost.', undefined, () => {
				closeConfirmDialog();
				close(shouldRefresh);
			}, 'Cancel', 'Close');
		} else {
			close(shouldRefresh);
		}

	};

	const close = (shouldRefresh = false) => {
		setLead({ other_ap: "n", witness_know_the_ap: "n", sameAsOffice: true, charges_software: false, tnc: false });
		setEmployees([])
		setErrorList({ employees: [] });
		setPhotosFileOb(null);
		setPANFileOb(null);
		setQualificationFileOb(null);
		setOfficeAddFileOb(null);
		setChequeFileOb(null);
		setResAddFileOb(null);
		setNISMFileOb(null);
		setOffSpaceFileOb(null);
		setPerVisitFileOb(null);
		props.onClose(shouldRefresh);
		setOpen(false);
	}

	const handleChange = field => e => {
		let value = e.target.value;
		if (field === 'mobile') {
			setLead(property => ({ ...property, [field]: value.substring(0, 10) }));
		} else if (field === 'bank_ac_no') {
			setLead(property => ({ ...property, [field]: value.substring(0, 25) }));
		} else {
			setLead(property => ({ ...property, [field]: value }));
		}
		setErrorList(e => ({ ...e, [field]: !value }));
		if (lead.sameAsOffice && field.includes('off')) {
			copyOffToRes();
		}
	}

	const handleEmployeeChange = index => e => {
		let field = e.target.name;
		let value = e.target.value;

		const updatedEmployees = employees.map((employee, i) => {
			if (i === index) {
				employee[field] = value
				return employee
			} else {
				return employee
			}
		})
		setEmployees(updatedEmployees)

		const updatedErrorList = errorList.employees.map((error, i) => {
			if (i === index) {
				error[field] = isEmpty(value)
				return error
			} else {
				return error
			}
		})
		setErrorList(e => ({ ...e, employees: updatedErrorList }));
	}

	const handleCheckChange = field => e => {
		let checked = e.target.checked;
		setLead(lead => ({ ...lead, [field]: checked }));

		if (field === 'sameAsOffice') {
			copyOffToRes();
		}
		// else if(field.includes('option_')){
		// 	setErrorList(e => ({...e, options: !lead.option_mcx && !lead.option_ncdex && !lead.option_nse_fo && !lead.option_nse_currency && !lead.option_nse}));
		// }
	}

	React.useEffect(() => {
		setErrorList(e => ({ ...e, options: !hasOneOptionSelected() }));
	}, [lead]);

	const copyOffToRes = () => {
		setLead(lead => ({ ...lead, res_add1: lead.off_add1, res_city: lead.off_city, res_state: lead.off_state, resPincodeOb: lead.offPincodeOb }));
		setErrorList(e => ({ ...e, res_add1: false, res_city: false, res_state: false, resPincodeOb: false }));
	}

	const addEmployee = () => {
		if (isEmpty(employees) || employees.length < maxEmployees) {
			setEmployees(employees => [...employees || [], {
				name: '',
				nism_completed: 'n'
			}])
			setErrorList(errorList => ({ ...errorList, employees: [...errorList.employees, { name: false }] }))
		}
	}

	const removeEmployee = index => {
		setEmployees(employees.filter((_, i) => i !== index))
		setErrorList(errorList => ({ ...errorList, employees: errorList.employees.filter((_, i) => i !== index) }))
	}

	const change = (field, file) => {
		console.log(field, file);
		if (field === 'PHOTO') {
			setPhotosFileOb(file);
		} else if (field === 'PAN') {
			setPANFileOb(file);
		} else if (field === 'CHEQUE') {
			setChequeFileOb(file);
		} else if (field === 'RES_ADD') {
			setResAddFileOb(file);
		} else if (field === 'OFF_ADD') {
			setOfficeAddFileOb(file);
		} else if (field === 'QUALIFICATION') {
			setQualificationFileOb(file);
		} else if (field === 'NISM') {
			setNISMFileOb(file);
		} else if (field === 'OFF_SPACE') {
			setOffSpaceFileOb(file);
		} else if (field === 'PER_VISIT') {
			setPerVisitFileOb(file);
		}

		// if(!file){
		// 	setLead(lead => ({...lead, [field]: null}));
		// }
	}

	const saveLead = () => {
		if (isValid()) {
			showConfirmDialog('Confirm Save Lead', undefined, () => {
				closeConfirmDialog();
				save();
			})
		}
	}

	const hasOneOptionSelected = () => {
		if (!lead.option_mcx && !lead.option_ncdex && !lead.option_nse_fo && !lead.option_nse_currency && !lead.option_nse_cash && !lead.option_bse_fo && !lead.option_bse_cash) {
			return false
		}
		return true
	}

	const isValid = () => {
		let valid = true;
		if (!lead.branch) {
			valid = false;
		}
		setErrorList(e => ({ ...e, branch: !lead.branch }))
		if (!lead.full_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, full_name: !lead.full_name }))
		if (!lead.father_husband_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, father_husband_name: !lead.father_husband_name }))
		if (!lead.dob) {
			valid = false;
		}
		setErrorList(e => ({ ...e, dob: !lead.dob }))
		if (!lead.mobile) {
			valid = false;
		}
		setErrorList(e => ({ ...e, mobile: !lead.mobile }))
		// if(!lead.landline){
		// 	valid = false;
		// }
		// setErrorList(e => ({...e, landline: !lead.landline}));
		if (!lead.email) {
			valid = false;
		}
		setErrorList(e => ({ ...e, email: !lead.email }));
		if (!lead.pan) {
			valid = false;
		}
		setErrorList(e => ({ ...e, pan: !lead.pan }));
		if (!lead.qualification) {
			valid = false;
		}
		setErrorList(e => ({ ...e, qualification: !lead.qualification }));
		if (!lead.income_range) {
			valid = false;
		}
		setErrorList(e => ({ ...e, income_range: !lead.income_range }));
		if (!lead.off_add1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, off_add1: !lead.off_add1 }));
		// if (!lead.off_add2) {
		// 	valid = false;
		// }
		// setErrorList(e => ({ ...e, off_add2: !lead.off_add2 }));
		if (!lead.offPincodeOb) {
			valid = false;
		}
		setErrorList(e => ({ ...e, offPincodeOb: !lead.offPincodeOb }))
		if (!lead.off_city) {
			valid = false;
		}
		setErrorList(e => ({ ...e, off_city: !lead.off_city }))
		if (!lead.off_state) {
			valid = false;
		}
		setErrorList(e => ({ ...e, off_state: !lead.off_state }))

		if (!lead.res_add1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, res_add1: !lead.res_add1 }));
		// if (!lead.res_add2) {
		// 	valid = false;
		// }
		// setErrorList(e => ({ ...e, res_add2: !lead.res_add2 }));
		if (!lead.resPincodeOb) {
			valid = false;
		}
		setErrorList(e => ({ ...e, resPincodeOb: !lead.resPincodeOb }))
		if (!lead.res_city) {
			valid = false;
		}
		setErrorList(e => ({ ...e, res_city: !lead.res_city }))
		if (!lead.res_state) {
			valid = false;
		}
		setErrorList(e => ({ ...e, res_state: !lead.res_state }))

		if (!hasOneOptionSelected()) {
			valid = false;
		}
		setErrorList(e => ({ ...e, options: !hasOneOptionSelected() }))

		if (!lead.bank_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, bank_name: !lead.bank_name }));
		if (!lead.bank_branch) {
			valid = false;
		}
		setErrorList(e => ({ ...e, bank_branch: !lead.bank_branch }));
		if (!lead.bank_ac_no) {
			valid = false;
		}
		setErrorList(e => ({ ...e, bank_ac_no: !lead.bank_ac_no }));
		if (!lead.bank_ifsc) {
			valid = false;
		}
		setErrorList(e => ({ ...e, bank_ifsc: !lead.bank_ifsc }));
		// if(!lead.security_deposit){
		// 	valid = false;
		// }
		// setErrorList(e => ({...e, security_deposit: !lead.security_deposit}));
		if (!lead.terminal) {
			valid = false;
		}
		setErrorList(e => ({ ...e, terminal: !lead.terminal }));
		if (lead.other_ap === 'y' && !lead.other_ap_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, other_ap_name: lead.other_ap === 'y' && !lead.other_ap_name }));
		if (lead.other_ap === 'y' && !lead.other_ap_relation) {
			valid = false;
		}
		setErrorList(e => ({ ...e, other_ap_relation: lead.other_ap === 'y' && !lead.other_ap_relation }));
		if (lead.other_ap === 'y' && !lead.other_ap_pan) {
			valid = false;
		}
		setErrorList(e => ({ ...e, other_ap_pan: lead.other_ap === 'y' && !lead.other_ap_pan }));
		if (lead.other_ap === 'y' && !lead.other_ap_trading_member_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, other_ap_trading_member_name: lead.other_ap === 'y' && !lead.other_ap_trading_member_name }));
		if (lead.other_ap === 'y' && !lead.other_ap_frch_reg_no) {
			valid = false;
		}
		setErrorList(e => ({ ...e, other_ap_frch_reg_no: lead.other_ap === 'y' && !lead.other_ap_frch_reg_no }));
		// if (!lead.entered_by) {
		// 	valid = false;
		// }
		// setErrorList(e => ({ ...e, entered_by: !lead.entered_by }));
		if (!lead.bm_code) {
			valid = false;
		}
		setErrorList(e => ({ ...e, bm_code: !lead.bm_code }));
		if (!lead.sm_code) {
			valid = false;
		}
		setErrorList(e => ({ ...e, sm_code: !lead.sm_code }));
		if (!lead.sh_code) {
			valid = false;
		}
		setErrorList(e => ({ ...e, sh_code: !lead.sh_code }));
		if (!lead.org_type) {
			valid = false;
		}
		setErrorList(e => ({ ...e, org_type: !lead.org_type }));
		if (!lead.tnc) {
			valid = false;
		}
		setErrorList(e => ({ ...e, tnc: !lead.tnc }));

		if (employees.length) {
			valid = !(employees.filter(employee => !employee.name).length > 0)
		}
		const updatedErrorList = errorList.employees.map((error, i) => {
			error.name = isEmpty(employees[i].name)
			return error
		})
		setErrorList(e => ({ ...e, employees: updatedErrorList }));

		// Witness
		if (lead.witness_know_the_ap === 'y' && !lead.witness_no_of_years) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness_no_of_years: lead.witness_know_the_ap === 'y' && !lead.witness_no_of_years }));
		if (!lead.witness1_full_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness1_full_name: !lead.witness1_full_name }));
		if (!lead.witness1_add1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness1_add1: !lead.witness1_add1 }));
		if (!lead.witness1PincodeOb) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness1PincodeOb: !lead.witness1PincodeOb }))
		if (!lead.witness1_city) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness1_city: !lead.witness1_city }))
		if (!lead.witness1_state) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness1_state: !lead.witness1_state }))
		if (!lead.witness2_full_name) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness2_full_name: !lead.witness2_full_name }));
		if (!lead.witness2_add1) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness2_add1: !lead.witness2_add1 }));
		if (!lead.witness2PincodeOb) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness2PincodeOb: !lead.witness2PincodeOb }))
		if (!lead.witness2_city) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness2_city: !lead.witness2_city }))
		if (!lead.witness2_state) {
			valid = false;
		}
		setErrorList(e => ({ ...e, witness3_state: !lead.witness3_state }))


		//Check Uploads
		if (!photosFileOb || !panFileOb || !qualificationFileOb || !resAddFileOb || !chequeFileOb
		) {
			valid = false;
			showConfirmDialog1D('Please Upload all required documents', undefined, () => {
				closeConfirmDialog1D();
			}, 'OK');
		} else if (!lead.tnc) {
			showConfirmDialog1D('Please accept the T&C', undefined, () => {
				closeConfirmDialog1D();
			}, 'OK');
		}

		return valid;
	}

	const save = () => {
		// setLoading(true);
		const formData = new FormData();
		formData.append("id", lead.id || null);
		formData.append("branch", lead.branch || '');
		formData.append("full_name", lead.full_name || '');
		formData.append("father_husband_name", lead.father_husband_name || '');
		formData.append("dob", lead.dob.format(format_without_time) || null);
		formData.append("off_add1", lead.off_add1 || '');
		// formData.append("off_add2", lead.off_add2 || '');
		// formData.append("off_add3", lead.off_add3 || '');
		formData.append("off_city", lead.off_city || '');
		formData.append("off_state", lead.off_state || '');
		formData.append("off_pincode", lead.offPincodeOb.pincode || '');
		formData.append("res_add1", lead.res_add1 || '');
		// formData.append("res_add2", lead.res_add2 || '');
		// formData.append("res_add3", lead.res_add3 || '');
		formData.append("res_city", lead.res_city || '');
		formData.append("res_state", lead.res_state || '');
		formData.append("res_pincode", lead.resPincodeOb.pincode || '');
		formData.append("org_type", lead.org_type || null);
		// formData.append("landline", lead.landline || '');
		formData.append("mobile", lead.mobile || null);
		formData.append("email", lead.email || null);
		formData.append("pan", lead.pan || null);
		formData.append("qualification", lead.qualification || null);
		formData.append("option_mcx", lead.option_mcx || false);
		formData.append("option_ncdex", lead.option_ncdex || false);
		formData.append("option_nse_cash", lead.option_nse_cash || false);
		formData.append("option_nse_fo", lead.option_nse_fo || false);
		formData.append("option_nse_currency", lead.option_nse_currency || false);
		formData.append("option_bse_cash", lead.option_bse_cash || false);
		formData.append("option_bse_fo", lead.option_bse_fo || false);
		formData.append("mtc_equity", lead.mtc_equity || 0);
		formData.append("mtc_commodities", lead.mtc_commodities || 0);
		formData.append("mtc_options", lead.mtc_options || 0);
		// formData.append("charges_software", lead.charges_software ? charges_software : 0);
		formData.append("brokerage_sharing_commodity", lead.brokerage_sharing_commodity || '');
		formData.append("brokerage_sharing_capital", lead.brokerage_sharing_capital || '');
		formData.append("bank_name", lead.bank_name || null);
		formData.append("bank_branch", lead.bank_branch || null);
		formData.append("bank_ac_no", lead.bank_ac_no || null);
		formData.append("bank_ifsc", lead.bank_ifsc || null);
		// formData.append("entered_by", lead.entered_by || null);
		formData.append("bm_code", lead.bm_code || null);
		formData.append("sm_code", lead.sm_code || null);
		formData.append("sh_code", lead.sh_code || null);
		formData.append("bm_email", lead.bm_email || null);
		formData.append("sm_email", lead.sm_email || null);
		formData.append("sh_email", lead.sh_email || null);
		if (lead.bm_mobile) formData.append("bm_mobile", lead.bm_mobile || null);
		if (lead.sm_mobile) formData.append("sm_mobile", lead.sm_mobile || null);
		if (lead.sh_mobile) formData.append("sh_mobile", lead.sh_mobile || null);
		formData.append("income_range", lead.income_range || null);
		// formData.append("security_deposit", lead.security_deposit || '');
		formData.append("terminal", lead.terminal || null);

		if (lead.other_ap_name) formData.append("other_ap_name", lead.other_ap_name || null);
		if (lead.other_ap_relation) formData.append("other_ap_relation", lead.other_ap_relation || null);
		if (lead.other_ap_pan) formData.append("other_ap_pan", lead.other_ap_pan || null);
		if (lead.other_ap_trading_member_name) formData.append("other_ap_trading_member_name", lead.other_ap_trading_member_name || null);
		if (lead.other_ap_frch_reg_no) formData.append("other_ap_frch_reg_no", lead.other_ap_frch_reg_no || null);
		// formData.append("other_ap_non_compliance", lead.other_ap_non_compliance === "1" ? 1 : 0);
		// formData.append("other_ap_investor_complaint", lead.other_ap_investor_complaint === "1" ? 1 : 0);
		formData.append("tnc", lead.tnc ? 1 : 0);

		// infrastructure
		formData.append("infra_no_of_computers", lead.infra_no_of_computers || null);
		formData.append("infra_employees", JSON.stringify(employees || []));

		// Witness
		formData.append("witness_know_the_ap", lead.witness_know_the_ap || 'n');
		formData.append("witness_no_of_years", lead.witness_no_of_years || null);
		formData.append("witness1_full_name", lead.witness1_full_name || null);
		formData.append("witness1_add1", lead.witness1_add1 || null);
		formData.append("witness1_city", lead.witness1_city || null);
		formData.append("witness1_state", lead.witness1_state || null);
		formData.append("witness1_pincode", lead.witness1PincodeOb.pincode || null);
		formData.append("witness2_full_name", lead.witness2_full_name || null);
		formData.append("witness2_add1", lead.witness2_add1 || null);
		formData.append("witness2_city", lead.witness2_city || null);
		formData.append("witness2_state", lead.witness2_state || null);
		formData.append("witness2_pincode", lead.witness2PincodeOb.pincode || null);


		formData.append("file_photo", photosFileOb || null);
		if (!lead.sameAsOffice) {
			formData.append("file_off_address", officeAddFileOb || null);
		}
		formData.append("file_res_address", resAddFileOb || null);
		formData.append("file_pan", panFileOb || null);
		formData.append("file_qualification", qualificationFileOb || null);
		formData.append("file_bank_cheque", chequeFileOb || null);
		formData.append("file_nism", nismFileOb || null);
		formData.append("file_off_space", offSpaceFileOb || null);
		formData.append("file_per_visit", perVisitFileOb || null);

		if (!formData.id) {
			showProgressDialog('Creating Lead...');
		} else {
			showProgressDialog('Saving Lead...');
		}

		LeadService.createLead(formData)
			.then(data => {
				if (data.success) {
					closeProgressDialog();
					handleClose(false, true);
				} else {
					closeProgressDialog();
					showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
						closeConfirmDialog1D();
					}, 'OK');
				}
			})
	}

	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{`${id ? '' : 'New '}Lead`}
					</Typography>
					{/* <Button autoFocus color="inherit" onClick={handleClose}>
					save
				</Button> */}
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={2} xs={12} lg={10} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Lead information"
								titleTypographyProps={{ variant: 'h4' }}
							/>
							<Divider />
							<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
								<Grid container justify="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
										{/* <Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Branch"
													value={lead.branch || ''}
													required
													error={errorList.branch}
													margin={fieldMargin}
													onChange={handleChange('branch')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid> */}
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Full Name"
													margin={fieldMargin}
													id="outlined-adornment-fullname"
													value={lead.full_name || ''}
													error={errorList.full_name}
													required
													onChange={handleChange('full_name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Father / Husband Name"
													margin={fieldMargin}
													id="outlined-adornment-father_husband_name"
													value={lead.father_husband_name || ''}
													error={errorList.father_husband_name}
													required
													onChange={handleChange('father_husband_name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<SingleDate
												date={lead.dob}
												margin={fieldMargin}
												required={true}
												error={errorList.dob}
												setDate={date => setLead(lead => ({ ...lead, dob: date }))}
												maxDate={moment()}
												label="Date of Birth" />
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Mobile"
													margin={fieldMargin}
													id="outlined-adornment-mobile"
													value={lead.mobile || ''}
													error={errorList.mobile}
													required
													type='text'
													onChange={handleChange('mobile')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
													InputProps={{
														inputProps: {
															pattern: '\\d{10,10}',
														},
														type: 'number'
													}}
												/>
											</FormControl>
										</Grid>
										{/* <Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Landline"
													margin={fieldMargin}
													id="outlined-adornment-landline"
													value={lead.landline || ''}
													onChange={handleChange('landline')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
													InputProps={{
														inputProps: {
															maxLength: 15
														},
														type: 'number'
													}}
												/>
											</FormControl>
										</Grid> */}
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Email"
													margin={fieldMargin}
													error={errorList.email}
													required
													id="outlined-adornment-email"
													value={lead.email || ''}
													onChange={handleChange('email')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-email',
														type: 'email'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										{/* <Grid item xs={12} lg={3}>
                                            <div style={{border: '1px solid rgb(0,0,0, 0.1)', paddingLeft: 8, borderRadius: 8, padding: 8}}>
                                                <Grid container spacing={2} justify="flex-end" alignItems="center" alignContent="center">
                                                    <Grid item>
                                                        <Typography variant="h6">
															{lead.filesSelected && lead.filesSelected.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
														<Button
															variant="contained"
															color="primary"
															onClick={() => hiddenFileInput.current.click()}
															startIcon={<CloudUploadIcon />}
														>
															Select
														</Button>
                                                        <input accept="image/*" type="file" onChange={uploadLogo} ref={hiddenFileInput} onClick={(event) =>  event.target.value = null} style={{display:'none'}} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid> */}
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="PAN"
													margin={fieldMargin}
													id="outlined-adornment-pan"
													value={lead.pan || ''}
													error={errorList.pan}
													required
													InputProps={{
														'aria-label': 'off_add1',
														inputProps: {
															maxLength: 10,
															style: {
																textTransform: 'uppercase'
															}
														}
													}}

													onChange={handleChange('pan')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='Qualification'
												value={qualificationOptions.find(i => i.value === lead.qualification) || null}
												optionLabel='name'
												error={errorList.qualification}
												options={qualificationOptions}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, qualification: value.value }));
													setErrorList(e => ({ ...e, qualification: !value }));
												}} />
											{/* <FormControl variant="outlined" fullWidth>
												<TextField
													label="Qualification"
													margin={fieldMargin}
													id="outlined-adornment-qualification"
													value={lead.qualification || ''}
													error={errorList.qualification}
													required
													onChange={handleChange('qualification')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
												
											</FormControl> */}
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='Income Rage (p.a)'
												value={incomeRange.find(i => i.value === lead.income_range) || null}
												optionLabel='name'
												error={errorList.income_range}
												options={incomeRange}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, income_range: value.value }));
													setErrorList(e => ({ ...e, income_range: !value }));
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='Type of Franchisee'
												value={ORG_TYPE.find(i => i.key === lead.org_type) || null}
												optionLabel='key'
												error={errorList.org_type}
												options={ORG_TYPE}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, org_type: value.key }));
													setErrorList(e => ({ ...e, org_type: !value }));
												}} />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Office Address</Typography>
										</Grid>
										<Grid item xs={12} lg={12}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 1"
													margin={fieldMargin}
													id="outlined-adornment-off_add1"
													value={lead.off_add1 || ''}
													required
													error={errorList.off_add1}
													onChange={handleChange('off_add1')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'off_add1',
														inputProps: {
															maxLength: 600,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										{/* <Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 2"
													margin={fieldMargin}
													id="outlined-adornment-off_add2"
													value={lead.off_add2 || ''}
													required
													error={errorList.off_add2}
													onChange={handleChange('off_add2')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'off_add2',
														inputProps: {
															maxLength: 100,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 3"
													margin={fieldMargin}
													id="outlined-adornment-off_add3"
													value={lead.off_add3 || ''}
													error={errorList.off_add3}
													onChange={handleChange('off_add3')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'off_add3',
														inputProps: {
															maxLength: 100,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid> */}
										<Grid item xs={12} lg={3}>
											<ItemSelectionAsync
												required
												size={fieldSize}
												label='Pincode'
												value={lead.offPincodeOb || null}
												optionLabel='pincode'
												labelTemplate={option => option.pincode}
												error={errorList.offPincodeOb}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, offPincodeOb: value, off_city: value.city, off_state: value.state }));
													setErrorList(e => ({ ...e, offPincodeOb: !value, off_city: !value.city, off_state: !value.state }));
													if (lead.sameAsOffice) {
														copyOffToRes();
													}
												}}
												renderOption={(option) => {
													return (
														<Grid container alignItems="center">
															<Grid item>
																<LocationOnIcon className={classes.icon} />
															</Grid>
															<Grid item xs>
																<span style={{ fontWeight: 400 }}>
																	{option.pincode}
																</span>
																<Typography variant="body2" color="textSecondary">
																	{`${option.city}, ${option.state}`}
																</Typography>
															</Grid>
														</Grid>
													);
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="City"
													margin={fieldMargin}
													id="outlined-adornment-off_city"
													value={lead.off_city || ''}
													required
													error={errorList.off_city}
													onChange={handleChange('off_city')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'off_add3',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
											{/* <ItemSelection
												required 
												size={fieldSize}
												label='City'
												value={lead.offCityOb || null} 
												optionLabel='name'
												error={errorList.offCityOb}
												options={types} 
												style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({...lead, offCityOb: value}));
												}}/> */}
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="State"
													margin={fieldMargin}
													id="outlined-adornment-off_state"
													value={lead.off_state || ''}
													required
													error={errorList.off_state}
													onChange={handleChange('off_state')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'off_add3',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid container item xs={12} lg={12} alignItems="center">
											<Grid item>
												<Typography variant="h6" className={classes.subtitle}>Residence Address</Typography>
											</Grid>
											<Grid item>
												<FormControlLabel
													control={
														<Checkbox
															checked={lead.sameAsOffice}
															onChange={handleCheckChange('sameAsOffice')}
															name="checkedB"
															color="primary"
														/>
													}
													style={{ marginLeft: 8 }}
													label="Same as Office Address"
												/>
											</Grid>
										</Grid>
										{
											!lead.sameAsOffice ? (
												<>
													<Grid item xs={12} lg={12}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Address Line 1"
																margin={fieldMargin}
																disabled={lead.sameAsOffice}
																id="outlined-adornment-res_add1"
																value={lead.res_add1 || ''}
																required
																error={errorList.res_add1}
																onChange={handleChange('res_add1')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'res_add1',
																	inputProps: {
																		maxLength: 600,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													{/* <Grid item xs={12} lg={4}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Address Line 2"
																margin={fieldMargin}
																disabled={lead.sameAsOffice}
																id="outlined-adornment-res_add2"
																value={lead.res_add2 || ''}
																required
																error={errorList.res_add2}
																onChange={handleChange('res_add2')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'res_add2',
																	inputProps: {
																		maxLength: 100,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={4}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Address Line 3"
																margin={fieldMargin}
																disabled={lead.sameAsOffice}
																id="outlined-adornment-res_add3"
																value={lead.res_add3 || ''}
																error={errorList.res_add3}
																onChange={handleChange('res_add3')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'res_add3',
																	inputProps: {
																		maxLength: 100,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid> */}
													<Grid item xs={12} lg={3}>
														<ItemSelectionAsync
															required
															size={fieldSize}
															label='Pincode'
															disabled={lead.sameAsOffice}
															value={lead.resPincodeOb || null}
															optionLabel='pincode'
															labelTemplate={option => option.pincode}
															error={errorList.resPincodeOb}
															style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
															formClass={classes.formAutoClass}
															selected={(value) => {
																setLead(lead => ({ ...lead, resPincodeOb: value, res_city: value.city, res_state: value.state }));
																setErrorList(e => ({ ...e, resPincodeOb: !value, res_city: !value.city, res_state: !value.state }));
															}}
															renderOption={(option) => {
																return (
																	<Grid container alignItems="center">
																		<Grid item>
																			<LocationOnIcon className={classes.icon} />
																		</Grid>
																		<Grid item xs>
																			<span style={{ fontWeight: 400 }}>
																				{option.pincode}
																			</span>
																			<Typography variant="body2" color="textSecondary">
																				{`${option.city}, ${option.state}`}
																			</Typography>
																		</Grid>
																	</Grid>
																);
															}} />
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="City"
																disabled={lead.sameAsOffice}
																margin={fieldMargin}
																id="outlined-adornment-res_city"
																value={lead.res_city || ''}
																required
																error={errorList.res_city}
																onChange={handleChange('res_city')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'off_add3',
																	inputProps: {
																		maxLength: 100,
																	}
																}}
																style={{ marginTop: 4 }}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="State"
																margin={fieldMargin}
																disabled={lead.sameAsOffice}
																id="outlined-adornment-res_state"
																value={lead.res_state || ''}
																required
																error={errorList.res_state}
																onChange={handleChange('res_state')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'res_state',
																	inputProps: {
																		maxLength: 100,
																	}
																}}
																style={{ marginTop: 4 }}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
												</>
											) : null
										}
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Bank Details</Typography>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Bank Name"
													margin={fieldMargin}
													error={errorList.bank_name}
													required
													id="outlined-adornment-bank-name"
													value={lead.bank_name || ''}
													onChange={handleChange('bank_name')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-bank_name',
														inputProps: {
															maxLength: 40,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Branch"
													margin={fieldMargin}
													error={errorList.bank_branch}
													required
													id="outlined-adornment-bank-branch"
													value={lead.bank_branch || ''}
													onChange={handleChange('bank_branch')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-bank_branch',
														inputProps: {
															maxLength: 40,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Account No"
													margin={fieldMargin}
													error={errorList.bank_ac_no}
													required
													id="outlined-adornment-bank-acno"
													value={lead.bank_ac_no || ''}
													onChange={handleChange('bank_ac_no')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-bank_ac_no',
														inputProps: {
															pattern: '\\d{25}',
														},
														type: 'number'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="IFSC"
													error={errorList.bank_ifsc}
													required
													margin={fieldMargin}
													id="outlined-adornment-bank-ifsc"
													value={lead.bank_ifsc || ''}
													onChange={handleChange('bank_ifsc')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-bank_ifsc',
														inputProps: {
															maxLength: 20,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Segments</Typography>
										</Grid>
										<Grid item xs={12} lg={12}>
											<FormControl required error={errorList.options}>
												<FormLabel component="legend">Pick atleast one segment</FormLabel>
												<FormGroup row>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_mcx || false}
																onChange={handleCheckChange('option_mcx')}
																name="checkedB"
																color="primary"
															/>
														}
														label="MCX"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_ncdex || false}
																onChange={handleCheckChange('option_ncdex')}
																name="checkedB"
																color="primary"
															/>
														}
														label="NCDEX"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_nse_cash || false}
																onChange={handleCheckChange('option_nse_cash')}
																name="checkedB"
																color="primary"
															/>
														}
														label="NSE Cash"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_nse_fo || false}
																onChange={handleCheckChange('option_nse_fo')}
																name="checkedB"
																color="primary"
															/>
														}
														label="NSE F&O"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_nse_currency || false}
																onChange={handleCheckChange('option_nse_currency')}
																name="checkedB"
																color="primary"
															/>
														}
														label="NSE Currencies"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_bse_cash || false}
																onChange={handleCheckChange('option_bse_cash')}
																name="checkedB"
																color="primary"
															/>
														}
														label="BSE Cash"
													/>
													<FormControlLabel
														control={
															<Checkbox
																checked={lead.option_bse_fo || false}
																onChange={handleCheckChange('option_bse_fo')}
																name="checkedB"
																color="primary"
															/>
														}
														label="BSE F&O"
													/>
												</FormGroup>
												{/* <FormHelperText>You can display an error</FormHelperText> */}
											</FormControl>
										</Grid>
										{/* <Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Other Charges</Typography>
											<FormControlLabel
												control={
													<Checkbox
														checked={lead.charges_software}
														name="checkedB"
														color="primary"
														onChange={handleCheckChange('charges_software')}
													/>
												}
												label={`Software Charges ${formatNumber(charges_software)}`}
											/>
										</Grid> */}
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item container justify="flex-start" spacing={1} alignItems="flex-start" xs={12} lg={12}>
											<Grid item xs={12} lg={3}>
												<FormControl variant="outlined" fullWidth>
													<TextField
														label="Brokerage Sharing Commodity"
														margin={fieldMargin}
														id="outlined-adornment-customer-bsco"
														value={lead.brokerage_sharing_commodity || ''}
														onChange={handleChange('brokerage_sharing_commodity')}
														aria-describedby="outlined-weight-helper-text"
														InputProps={{
															'aria-label': 'customer-brokerage_sharing_commodity',
															inputProps: {
																maxLength: 100,
															},
															//endAdornment: <InputAdornment position="end"></InputAdornment>
														}}
														helperText="% to Franchisee"
														variant="outlined"
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12} lg={3}>
												<FormControl variant="outlined" fullWidth>
													<TextField
														label="Brokerage Sharing Capital"
														margin={fieldMargin}
														id="outlined-adornment-customer-bsca"
														value={lead.brokerage_sharing_capital || ''}
														onChange={handleChange('brokerage_sharing_capital')}
														aria-describedby="outlined-weight-helper-text"
														InputProps={{
															'aria-label': 'customer-brokerage_sharing_capital',
															inputProps: {
																maxLength: 100,
															},
															//endAdornment: <InputAdornment position="end"></InputAdornment>
														}}
														variant="outlined"
														helperText="% to Franchisee"
													/>
												</FormControl>
											</Grid>
											{/* <Grid item xs={12} lg={3}>
												<FormControl variant="outlined" fullWidth>
													<TextField
														label="Security Deposit"
														margin={fieldMargin}
														error={errorList.security_deposit}
														id="outlined-adornment-security-deposit"
														value={lead.security_deposit || ''}
														onChange={handleChange('security_deposit')}
														aria-describedby="outlined-weight-helper-text"
														InputProps={{
															'aria-label': 'customer-security_deposit',
															inputProps: {
																maxLength: 100,
															}
														}}
														variant="outlined"
													/>
												</FormControl>
											</Grid> */}
											<Grid item xs={12} lg={3}>
												<ItemSelection
													required
													size={fieldSize}
													label='Terminal'
													value={terminalOptions.find(i => i.value === lead.terminal) || null}
													optionLabel='name'
													error={errorList.terminal}
													options={terminalOptions}
													style={{ zIndex: 500, marginTop: 8, marginLeft: 0 }}
													formClass={classes.formAutoClass}
													selected={(value) => {
														setLead(lead => ({ ...lead, terminal: value.value }));
														setErrorList(e => ({ ...e, terminal: !value }));
													}}
													helperText={!isEmpty(lead.terminal) && lead.terminal !== 'No terminal' ? "Rs. 200 will be debited from your ledger balance" : ""} />
											</Grid>
											{/* <Grid item xs={12} lg={12}>
												<Typography variant="body2">Minimum to the company for equity is Rs. 500, commodity is Rs 300 & options is Rs 5. For anything below those slab, please take a special approval by clicking <Mailto subject="Special Approval for Brokerage Sharing" email="sarath.s@acumengroup.in;binu.joseph@acumengroup.in;biju.theruvan@acumengroup.in">here</Mailto>.</Typography>
											</Grid> */}
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Minimum to Company</Typography>
											{/* masterCharges */}
										</Grid>
										{
											masterCharges.length > 0 && (
												<Grid item xs={12} lg={12}>
													<Typography variant="body2">{`Minimum to the company for equity is ${masterCharges && masterCharges.find(v => v.field === 'mtc_equity') && formatNumber(masterCharges.find(v => v.field === 'mtc_equity').value || 0)}, commodity is ${masterCharges && masterCharges.find(v => v.field === 'mtc_commodities') && formatNumber(masterCharges.find(v => v.field === 'mtc_commodities').value)} & options is ${masterCharges && masterCharges.find(v => v.field === 'mtc_options') && formatNumber(masterCharges.find(v => v.field === 'mtc_options').value)}. For any changes, please specify the same below.`}</Typography>
												</Grid>
											)
										}
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Equity"
													margin={fieldMargin}
													id="outlined-adornment-mtc_equity"
													value={lead.mtc_equity || ''}
													onChange={handleChange('mtc_equity')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-mtc_equity',
														inputProps: {
															maxLength: 10,
														},
														type: 'number',
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Commodity"
													margin={fieldMargin}
													id="outlined-adornment-mtc_commodities"
													value={lead.mtc_commodities || ''}
													onChange={handleChange('mtc_commodities')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-mtc_commodities',
														inputProps: {
															maxLength: 10,
														},
														type: 'number',
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Options"
													margin={fieldMargin}
													id="outlined-adornment-mtc_options"
													value={lead.mtc_options || ''}
													onChange={handleChange('mtc_options')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-mtc_options',
														inputProps: {
															maxLength: 10,
														},
														type: 'number',
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Infrastructure details</Typography>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Number of computers (approx.)"
													margin={fieldMargin}
													error={errorList.infra_no_of_computers}
													required
													id="outlined-adornment-infra_no_of_computers"
													value={lead.infra_no_of_computers || ''}
													onChange={handleChange('infra_no_of_computers')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-infra_no_of_computers',
														inputProps: {
															pattern: '\\d',
														},
														type: 'number'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="body2" component="span" color='initial'>
												Employee details
											</Typography>
										</Grid>
										{
											!isEmpty(employees) ? (
												employees.map((employee, i) => (
													<Grid key={i} item container justify='flex-start' alignItems='center' spacing={2} xs={12} lg={12}>
														<Grid item>
															{i + 1}
														</Grid>
														<Grid item xs={12} lg={3}>
															<FormControl variant="outlined" fullWidth>
																<TextField
																	label="Name"
																	margin={fieldMargin}
																	error={errorList.employees[i] ? errorList.employees[i].name : false}
																	required
																	name='name'
																	value={employee.name || ''}
																	onChange={handleEmployeeChange(i)}
																	aria-describedby="outlined-weight-helper-text"
																	InputProps={{
																		'aria-label': 'customer-employee-name',
																		inputProps: {
																			maxLength: 40,
																		}
																	}}
																	variant="outlined"
																/>
															</FormControl>
														</Grid>
														<Grid item>
															<FormControl component="fieldset">
																NISM completed
																<RadioGroup row aria-label="employee-nism_completed" name="nism_completed" value={employee.nism_completed || 'n'} onChange={handleEmployeeChange(i)}>
																	<FormControlLabel value="y" control={<Radio />} label="Yes" />
																	<FormControlLabel value="n" control={<Radio />} label="No" />
																</RadioGroup>
															</FormControl>
														</Grid>
														<Grid>
															<IconButton onClick={() => removeEmployee(i)}>
																<DeleteIcon />
															</IconButton>
														</Grid>
													</Grid>
												))
											) : null
										}
										<Grid item xs={12} lg={12}>
											<Button onClick={addEmployee} disabled={employees.length === 5} color='primary' size="small" variant='contained' endIcon={<Icon>add</Icon>}>
												Add
											</Button>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Any other AP registration in your name, spouse, relative etc.</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl component="fieldset">
												<RadioGroup row aria-label="other_ap" name="other_ap" value={lead.other_ap || 'n'} onChange={handleChange('other_ap')}>
													<FormControlLabel value="y" control={<Radio />} label="Yes" />
													<FormControlLabel value="n" control={<Radio />} label="No" />
												</RadioGroup>
											</FormControl>
										</Grid>
										{
											lead.other_ap && lead.other_ap === 'y' && (
												<Grid item container justify="flex-start" spacing={1} alignItems="center" xs={12} lg={12}>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Name"
																margin={fieldMargin}
																error={errorList.other_ap_name}
																required
																id="outlined-adornment-other_ap_name"
																value={lead.other_ap_name || ''}
																onChange={handleChange('other_ap_name')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'customer-other_ap_name',
																	inputProps: {
																		maxLength: 40,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Relation"
																margin={fieldMargin}
																error={errorList.other_ap_relation}
																required
																id="outlined-adornment-other_ap_relation"
																value={lead.other_ap_relation || ''}
																onChange={handleChange('other_ap_relation')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'customer-other_ap_relation',
																	inputProps: {
																		maxLength: 40,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="PAN"
																margin={fieldMargin}
																error={errorList.other_ap_pan}
																required
																id="outlined-adornment-other_ap_pan"
																value={lead.other_ap_pan || ''}
																onChange={handleChange('other_ap_pan')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'customer-other_ap_pan',
																	inputProps: {
																		maxLength: 10,
																		style: {
																			textTransform: 'uppercase'
																		}
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Trading Member Name"
																margin={fieldMargin}
																error={errorList.other_ap_trading_member_name}
																required
																id="outlined-adornment-other_ap_trading_member_name"
																value={lead.other_ap_trading_member_name || ''}
																onChange={handleChange('other_ap_trading_member_name')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'customer-other_ap_trading_member_name',
																	inputProps: {
																		maxLength: 50,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={3}>
														<FormControl variant="outlined" fullWidth>
															<TextField
																label="Frachisee Registration No"
																margin={fieldMargin}
																error={errorList.other_ap_frch_reg_no}
																required
																id="outlined-adornment-other_ap_frch_reg_no"
																value={lead.other_ap_frch_reg_no || ''}
																onChange={handleChange('other_ap_frch_reg_no')}
																aria-describedby="outlined-weight-helper-text"
																InputProps={{
																	'aria-label': 'customer-other_ap_frch_reg_no',
																	inputProps: {
																		maxLength: 40,
																	}
																}}
																variant="outlined"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} lg={2} />
													{/* <Grid container item xs={12} lg={12} alignItems="center">
														<Grid item xs={12} lg={5} xl={4}>
															<Typography component="span" style={{ marginRight: 8, marginTop: 8 }} variant="body1">Any past non-compliance observed from any exchange?</Typography>
														</Grid>
														<Grid item>
															<FormControl component="fieldset">
																<RadioGroup row aria-label="other_ap_non_compliance" name="other_ap_non_compliance" value={lead.other_ap_non_compliance} onChange={handleChange('other_ap_non_compliance')}>
																	<FormControlLabel value={"1"} control={<Radio />} label="Yes" />
																	<FormControlLabel value={"0"} control={<Radio />} label="No" />
																</RadioGroup>
															</FormControl>
														</Grid>
													</Grid>
													<Grid container item xs={12} lg={12} alignItems="center">
														<Grid item xs={12} lg={5} xl={4}>
															<Typography component="span" style={{ marginRight: 8, marginTop: 8 }} variant="body1">Any existing investor compliant pending against this AP?</Typography>
														</Grid>
														<Grid item>
															<FormControl component="fieldset">
																<RadioGroup row aria-label="other_ap_investor_complaint" name="other_ap_investor_complaint" value={lead.other_ap_investor_complaint} onChange={handleChange('other_ap_investor_complaint')}>
																	<FormControlLabel value={"1"} control={<Radio />} label="Yes" />
																	<FormControlLabel value={"0"} control={<Radio />} label="No" />
																</RadioGroup>
															</FormControl>
														</Grid>
													</Grid>
													<Grid item xs={12} lg={4}>
													</Grid> */}
												</Grid>
											)
										}
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Witness</Typography>
										</Grid>
										<Grid container item xs={12} lg={12} alignItems="center">
											<Grid item xs={12} lg={3} xl={2}>
												<Typography component="span" style={{ marginRight: 8, marginTop: 8 }} variant="body1">Do you personally know the AP?</Typography>
											</Grid>
											<Grid item>
												<FormControl component="fieldset">
													<RadioGroup row aria-label="witness_know_the_ap" name="witness_know_the_ap" value={lead.witness_know_the_ap} onChange={handleChange('witness_know_the_ap')}>
														<FormControlLabel value={"y"} control={<Radio />} label="Yes" />
														<FormControlLabel value={"n"} control={<Radio />} label="No" />
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item xs={12} lg={3}>
												{lead.witness_know_the_ap === 'y' ? (
													<ItemSelection
														required
														size={fieldSize}
														label='If yes, number of years'
														value={witnessNoOfYearsOptions.find(i => i.value === lead.witness_no_of_years) || null}
														optionLabel='name'
														error={errorList.witness_no_of_years}
														options={witnessNoOfYearsOptions}
														style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
														formClass={classes.formAutoClass}
														selected={(value) => {
															setLead(lead => ({ ...lead, witness_no_of_years: value.value }));
															setErrorList(e => ({ ...e, witness_no_of_years: !value }));
														}} />
												) : null}
											</Grid>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="subtitle2" className={classes.subtitle}>Witness 1</Typography>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Full Name"
													margin={fieldMargin}
													value={lead.witness1_full_name || ''}
													error={errorList.witness1_full_name}
													required
													onChange={handleChange('witness1_full_name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 1"
													margin={fieldMargin}
													value={lead.witness1_add1 || ''}
													required
													error={errorList.witness1_add1}
													onChange={handleChange('witness1_add1')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness1_add1',
														inputProps: {
															maxLength: 600,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelectionAsync
												required
												size={fieldSize}
												label='Pincode'
												value={lead.witness1PincodeOb || null}
												optionLabel='pincode'
												labelTemplate={option => option.pincode}
												error={errorList.witness1PincodeOb}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, witness1PincodeOb: value, witness1_city: value.city, witness1_state: value.state }));
													setErrorList(e => ({ ...e, witness1PincodeOb: !value, witness1_city: !value.city, witness1_state: !value.state }));
												}}
												renderOption={(option) => {
													return (
														<Grid container alignItems="center">
															<Grid item>
																<LocationOnIcon className={classes.icon} />
															</Grid>
															<Grid item xs>
																<span style={{ fontWeight: 400 }}>
																	{option.pincode}
																</span>
																<Typography variant="body2" color="textSecondary">
																	{`${option.city}, ${option.state}`}
																</Typography>
															</Grid>
														</Grid>
													);
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="City"
													margin={fieldMargin}
													value={lead.witness1_city || ''}
													required
													error={errorList.witness1_city}
													onChange={handleChange('witness1_city')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness1_city',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="State"
													margin={fieldMargin}
													value={lead.witness1_state || ''}
													required
													error={errorList.witness1_state}
													onChange={handleChange('witness1_state')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness1_state',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="subtitle2" className={classes.subtitle}>Witness 2</Typography>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Full Name"
													margin={fieldMargin}
													value={lead.witness2_full_name || ''}
													error={errorList.witness2_full_name}
													required
													onChange={handleChange('witness2_full_name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 1"
													margin={fieldMargin}
													value={lead.witness2_add1 || ''}
													required
													error={errorList.witness2_add1}
													onChange={handleChange('witness2_add1')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness2_add1',
														inputProps: {
															maxLength: 600,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelectionAsync
												required
												size={fieldSize}
												label='Pincode'
												value={lead.witness2PincodeOb || null}
												optionLabel='pincode'
												labelTemplate={option => option.pincode}
												error={errorList.witness2PincodeOb}
												style={{ zIndex: 500, marginTop: 0, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, witness2PincodeOb: value, witness2_city: value.city, witness2_state: value.state }));
													setErrorList(e => ({ ...e, witness2PincodeOb: !value, witness2_city: !value.city, witness2_state: !value.state }));
												}}
												renderOption={(option) => {
													return (
														<Grid container alignItems="center">
															<Grid item>
																<LocationOnIcon className={classes.icon} />
															</Grid>
															<Grid item xs>
																<span style={{ fontWeight: 400 }}>
																	{option.pincode}
																</span>
																<Typography variant="body2" color="textSecondary">
																	{`${option.city}, ${option.state}`}
																</Typography>
															</Grid>
														</Grid>
													);
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="City"
													margin={fieldMargin}
													value={lead.witness2_city || ''}
													required
													error={errorList.witness2_city}
													onChange={handleChange('witness2_city')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness2_city',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="State"
													margin={fieldMargin}
													value={lead.witness2_state || ''}
													required
													error={errorList.witness2_state}
													onChange={handleChange('witness2_state')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'witness2_state',
														inputProps: {
															maxLength: 100,
														}
													}}
													style={{ marginTop: 4 }}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Terms & Conditions</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="subtitle2">
												Please agree to the below T&C
												<ol>
													<li>The AP is not authorized to offer any kind of guaranteed/assured return to any clients.</li>
													<li>There are no direct cash dealings with clients.</li>
													<li>Not authorized to send any trading-related related documents to clients.</li>
													<li>No past non-compliance was observed from any exchanges.</li>
													<li>There are no existing investor(s) complaints pending against this AP.</li>
													<li>Informed the AP about the trading risk in the stock and commodity market.</li>
												</ol>
											</Typography>
											{/* <Grid item> */}
											<FormControlLabel
												control={
													<Checkbox
														checked={lead.tnc}
														onChange={handleCheckChange('tnc')}
														name="checkedB"
														color="primary"
													/>
												}
												style={{ marginLeft: 8 }}
												label="I Agree with the above Dos & Don'ts."
											/>
											{/* </Grid> */}
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} lg={12}>
											<Typography variant="h6" className={classes.subtitle}>Concern Team</Typography>
										</Grid>
										{/* <Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Entered By"
													margin={fieldMargin}
													error={errorList.entered_by}
													required
													id="outlined-adornment-entered-by"
													value={lead.entered_by || ''}
													onChange={handleChange('entered_by')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-entered_by',
														inputProps: {
															maxLength: 30,
														}
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid> */}
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='Branch Manager'
												value={bmOptions.find(i => i.uname === lead.uname) || null}
												labelTemplate={(option) => `${option.branch} - ${option.username}`}
												optionLabel="uname"
												error={errorList.bm_code}
												options={bmOptions}
												style={{ zIndex: 500, marginTop: 8, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, bm_code: value.uname, branch: value.branch, bm_mobile: value.mobile, bm_email: value.email }));
													setErrorList(e => ({ ...e, bm_code: !value }));
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='State Head'
												value={smOptions.find(i => i.uname === lead.uname) || null}
												optionLabel='username'
												error={errorList.sm_code}
												options={smOptions}
												style={{ zIndex: 500, marginTop: 8, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, sm_code: value.uname, sm_mobile: value.mobile, sm_email: value.email }));
													setErrorList(e => ({ ...e, sm_code: !value }));
												}} />
										</Grid>
										<Grid item xs={12} lg={3}>
											<ItemSelection
												required
												size={fieldSize}
												label='Sales Head'
												value={shOptions.find(i => i.uname === lead.uname) || null}
												optionLabel='username'
												error={errorList.sh_code}
												options={shOptions}
												style={{ zIndex: 500, marginTop: 8, marginLeft: 0 }}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setLead(lead => ({ ...lead, sh_code: value.uname, sh_mobile: value.mobile, sh_email: value.email }));
													setErrorList(e => ({ ...e, sh_code: !value }));
												}} />
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Upload Proofs"
								titleTypographyProps={{ variant: 'h4' }}
								subheader="Formats Accepted are JPG, PNG, PDF"
							/>
							<Divider />
							<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Photo<sup className={classes.required}>*</sup></Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Photo"
													link={lead.documents && lead.documents.find(d => d.document_type === 'PHOTO') ? lead.documents.find(d => d.document_type === 'PHOTO').link : null}
													change={(file) => {
														change('PHOTO', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('PHOTO', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Qualification<sup className={classes.required}>*</sup></Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="QUALIFICATION"
													link={lead.documents && lead.documents.find(d => d.document_type === 'QUALIFICATION') ? lead.documents.find(d => d.document_type === 'QUALIFICATION').link : null}
													change={(file) => {
														change('QUALIFICATION', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('QUALIFICATION', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>PAN<sup className={classes.required}>*</sup></Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="PAN"
													link={lead.documents && lead.documents.find(d => d.document_type === 'PAN') ? lead.documents.find(d => d.document_type === 'PAN').link : null}
													change={(file) => {
														change('PAN', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('PAN', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>NISM Certificate</Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="NISM"
													link={lead.documents && lead.documents.find(d => d.document_type === 'NISM') ? lead.documents.find(d => d.document_type === 'NISM').link : null}
													change={(file) => {
														change('NISM', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('NISM', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Cancelled cheque/Last 6 months statement<sup className={classes.required}>*</sup></Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Cheque"
													link={lead.documents && lead.documents.find(d => d.document_type === 'CHEQUE') ? lead.documents.find(d => d.document_type === 'CHEQUE').link : null}
													change={(file) => {
														change('CHEQUE', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('CHEQUE', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Personal Visit</Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Personal Visit"
													link={lead.documents && lead.documents.find(d => d.document_type === 'PER_VISIT') ? lead.documents.find(d => d.document_type === 'PER_VISIT').link : null}
													change={(file) => {
														change('PER_VISIT', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('PER_VISIT', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Residence Address<sup className={classes.required}>*</sup></Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Residence Address"
													link={lead.documents && lead.documents.find(d => d.document_type === 'RES_ADD') ? lead.documents.find(d => d.document_type === 'RES_ADD').link : null}
													change={(file) => {
														change('RES_ADD', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('RES_ADD', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={3} lg={3}>
										<Typography variant="h5" className={classes.header}>Office Space</Typography>
									</Grid>
									<Grid item xs={9} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Office Space"
													link={lead.documents && lead.documents.find(d => d.document_type === 'OFF_SPACE') ? lead.documents.find(d => d.document_type === 'OFF_SPACE').link : null}
													change={(file) => {
														change('OFF_SPACE', file);
													}}
													remove={(newDocument) => {
														if (newDocument) {
															change('OFF_SPACE', null);
														}
													}}
												/>
											</div>
										}
									</Grid>
									{!lead.sameAsOffice ? (
										<>
											<Grid item xs={3} lg={3}>
												<Typography variant="h5" className={classes.header}>Office Address<sup className={classes.required}>*</sup></Typography>
											</Grid>
											<Grid item xs={9} lg={3}>
												{
													<div>
														<DocumentPlaceHolder
															error={false}
															title="Office Address"
															link={lead.documents && lead.documents.find(d => d.document_type === 'OFF_ADD') ? lead.documents.find(d => d.document_type === 'OFF_ADD').link : null}
															change={(file) => {
																change('OFF_ADD', file);
															}}
															remove={(newDocument) => {
																if (newDocument) {
																	change('OFF_ADD', null);
																}
															}}
														/>
													</div>
												}
											</Grid>
										</>
									) : null}
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" style={{ marginLeft: 16, marginRight: 16 }} elevation={3}>
							<div style={{ marginRight: 16, marginLeft: 16, marginTop: 16, marginBottom: 16 }}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={12}>
										<div style={{ float: 'right' }}>
											<Button variant="contained" color="primary" onClick={saveLead}>Save</Button>
										</div>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Dialog >
	);
}

function DocumentPlaceHolder(props) {
	let classes = useStyles();
	// const {error} = props;
	const [link, setLink] = React.useState(null);
	const [newFile, setNewFile] = React.useState(null);

	const accept = props.accept || "image/*,application/pdf";

	const hiddenFileInput = React.useRef(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link]);

	const handleClick = () => {
		hiddenFileInput.current.click();
	}

	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setNewFile(fileUploaded);
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	return (
		<div>
			<ButtonGroup disableElevation size="small" color="inherit" aria-label="outlined primary button group">
				{
					link && (
						<Button
							style={{ color: red[500] }}
							onClick={() => props.remove(false)}
						>Remove
						</Button>
					)
				}
				{
					!link && !newFile && (
						<Button onClick={handleClick}>Upload</Button>
					)
				}
				{
					newFile && (
						<Button
							color="primary"
							onClick={() => {
								props.remove(true);
								setNewFile(null);
							}}
							endIcon={<ClearIcon fontSize="small" />}>
							<span className={classes.truncate}>{newFile.name}</span>
							{/* Remove Selected */}
						</Button>
					)
				}
			</ButtonGroup>
			<input accept={accept} type="file" onChange={change} onClick={(event) => event.target.value = null} ref={hiddenFileInput} style={{ display: 'none' }} />
		</div>
	)
}

CreateLeadDialog.prototype = {
	open: PropTypes.bool.isRequired,
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired
};